import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import AddPartyForm from "./AddPartyForm";

const LocalParty = () => {
  const [parties, setParties] = useState([]);
  const [filteredParties, setFilteredParties] = useState([]);
  const [partyTypeFilter, setPartyTypeFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [showForm, setShowForm] = useState(false);

  // Fetch initial party data (replace with your actual API endpoint)
  useEffect(() => {
    const fetchParties = async () => {
      const response = await fetch("/api/parties"); // Replace with your API endpoint
      const data = await response.json();
      setParties(data);
      setFilteredParties(data);
    };
    fetchParties();
  }, []);

  useEffect(() => {
    const filtered = parties.filter((party) => {
      const typeMatch =
        partyTypeFilter === "all" || party.type === partyTypeFilter;
      const dateMatch =
        !dateFilter ||
        new Date(party.date).toLocaleDateString() ===
          new Date(dateFilter).toLocaleDateString();
      return typeMatch && dateMatch;
    });
    setFilteredParties(filtered);
  }, [parties, partyTypeFilter, dateFilter]);

  const handlePartyTypeFilterChange = (event) => {
    setPartyTypeFilter(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleAddParty = (newParty) => {
    setParties([...parties, newParty]);
  };

  return (
    <div>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        style={{ height: "84vh" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {filteredParties.map((party) => (
          <Marker key={party.id} position={[party.latitude, party.longitude]}>
            <Popup>
              <h3>{party.name}</h3>
              <p>{party.type}</p>
              <p>{party.date}</p>
            </Popup>
          </Marker>
        ))}

        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1000,
            backgroundColor: "white",
            padding: "1rem 1rem 0.5rem 1rem",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="party-type-filter-label">Party Type</InputLabel>
              <Select
                labelId="party-type-filter-label"
                id="party-type-filter"
                value={partyTypeFilter}
                label="Party Type"
                onChange={handlePartyTypeFilterChange}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="birthday">Birthday</MenuItem>
                <MenuItem value="wedding">Wedding</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Date"
              type="date"
              value={dateFilter}
              onChange={handleDateFilterChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            zIndex: 1000,
            padding: "1rem",
          }}
        >
          <Button variant="contained" onClick={() => setShowForm(true)}>
            Add Your Party
          </Button>
        </div>

        {showForm && (
          <AddPartyForm
            onAddParty={handleAddParty}
            onClose={() => setShowForm(false)}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default LocalParty;
