import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useFavoritesStore = create(
  persist(
    (set) => ({
      favorites: [],
      toggleFavorite: (festivalId) =>
        set((state) => ({
          favorites: state.favorites.includes(festivalId)
            ? state.favorites.filter((id) => id !== festivalId) // Remove if already a favorite
            : [...state.favorites, festivalId], // Add if not a favorite
        })),
    }),
    {
      name: "favoritesStore", // unique name of the store
      getStorage: () => localStorage, // specify localStorage as the storage system
    }
  )
);
