import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useMapEvents } from "react-leaflet"; // Import useMapEvents hook

const AddPartyForm = ({ onAddParty, onClose }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [map, setMap] = useState(null); // Add state for the map instance

  const handleSubmit = (event) => {
    event.preventDefault();
    const newParty = { name, type, date, latitude, longitude };
    onAddParty(newParty);
    onClose(); // Close the modal after submission
  };
  const handleMapClick = (e) => {
    setLatitude(e.latlng.lat);
    setLongitude(e.latlng.lng);
  };

  // Custom component to handle map clicks
  const LocationPicker = () => {
    const mapInstance = useMapEvents({
      click: handleMapClick,
    });

    // Update the map state when the map instance is ready
    useEffect(() => {
      if (mapInstance) {
        setMap(mapInstance);
      }
    }, [mapInstance]);

    return null;
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Your Party
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Party Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="party-type-label">Party Type</InputLabel>
            <Select
              labelId="party-type-label"
              id="party-type"
              value={type}
              label="Party Type"
              onChange={(e) => setType(e.target.value)}
              required
            >
              <MenuItem value="birthday">Bachata</MenuItem>
              <MenuItem value="wedding">Kizomba</MenuItem>
              <MenuItem value="wedding">Salsa</MenuItem>
              <MenuItem value="wedding">West Cost</MenuItem>
              <MenuItem value="wedding">Zouk</MenuItem>
              {/* Add other party types as needed */}
            </Select>
          </FormControl>
          <TextField
            label="Date"
            type="date"
            fullWidth
            margin="normal"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Grid container spacing={2}>
            {/* City and Country fields side by side */}
            <Grid item xs={6}>
              <TextField
                label="City"
                fullWidth
                margin="normal"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Country"
                fullWidth
                margin="normal"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          {/* Input for location picker */}
          <TextField
            label="Location"
            fullWidth
            margin="normal"
            value={`${latitude}, ${longitude}`}
            onClick={() => alert("Click on the map to set the location")}
            readOnly
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
      <LocationPicker /> {/* Add the LocationPicker component */}
    </Modal>
  );
};

export default AddPartyForm;
